@tailwind base;
@tailwind components;
@tailwind utilities;

.ease {
  transition: all .4s ease;
  -webkit-transition: all .4s ease;
}

.easeFast {
  transition: all .2s ease;
  -webkit-transition: all .2s ease;
}

@font-face {
  font-family: squada;
  font-style: normal;
  src: url("./assets/fonts/SquadaOne-Regular.ttf")
}

@font-face {
  font-family: varela;
  font-style: normal;
  src: url("./assets/fonts/VarelaRound-Regular.ttf")
}

@font-face {
  font-family: inter;
  font-style: normal;
  src: url("./assets/fonts/inter.ttf")
}

@font-face {
  font-family: inter-bold;
  font-style: normal;
  src: url("./assets/fonts/Inter-Bold.ttf")
}

@font-face {
  font-family: raleway;
  font-style: normal;
  src: url("./assets/fonts/Raleway-Regular.ttf")
}

.dotted {
  background-color:
    #131A2B;
  /* fill-opacity: .50; */
  background-image: radial-gradient(#455068 5%, transparent 5%),
    radial-gradient(#455068 5%, transparent 5%);
  background-size: 30px 30px;
  background-position: 10px 10px, 20px 20px;
  background-repeat: repeat;
}

html {
  --scrollbarBG: #131A2B;
  --thumbBG: #90A4AE;
}

body::-webkit-scrollbar {
  width: 11px;
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}

body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.loader {
  border-top-color: #1068FF;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.scroll-text {
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  display: inline-block;
}

.scroll-text p {
  display: inline-block;
  padding-left: 100%;
  animation: scrolling 10s linear infinite;
}

@keyframes scrolling {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}